import React from 'react'
import logo from './assets/images/logo.png'
import video from './assets/video/index.mp4'
import './App.css'

const Copyright = () => {
    return (
        '©2000 - ' + new Date().getFullYear()
    )
}

class App extends React.Component {
    componentDidMount() {
        document.title = '闪石信息科技'
    }
    render() {
        return (
            <div className="App">
                <main className="App-main">
                    <img src={logo} className="App-logo" alt="logo" />
                    <div className="company">闪石信息科技（上海）有限公司</div>
                    <video
                        className='video'
                        loop
                        muted
                        autoPlay
                        playsInline
                        x5-playsinline="true"
                        webkit-playsinline="true"
                        x5-video-player-type="h5"
                    >
                        <source src={video} type="video/mp4"></source>
                    </video>
                </main>
                <div className="bottom">
                    <div className="icp"><Copyright />  闪石信息科技（上海）有限公司 备案: 沪ICP备15046145号-1</div>
                </div>
            </div>
        )
    }
}

export default App;
